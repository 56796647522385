import React from 'react'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { formatPrice } from '../../utils/formatPrice'
import { openModal } from '../../utils/openModal'
import { dismissModal } from '../../utils/dismissModal'
import { generateAddressText } from '../../utils/generateAddressText'
import { TrashIcon } from '../icons/TrashIcon'
import { ShoppingCartAddress } from '../../types'
import { setShoppingAddresses } from '../../store/reducers/shoppingCartReducer'
import { calculateGraduatedPrice } from '../../utils/calculateGraduatedPrice'

interface ShoppingShippingDetailsProps {
  setSelectedAssignmentAddress: React.Dispatch<React.SetStateAction<ShoppingCartAddress | null>>
  shippingMode: 'inventory' | 'delivery' | null
  setShippingMode: React.Dispatch<React.SetStateAction<'inventory' | 'delivery' | null>>
}
const ShoppingShippingDetails = ({ setSelectedAssignmentAddress, shippingMode, setShippingMode }: ShoppingShippingDetailsProps) => {
  const shoppingCartProducts = useAppSelector((state) => state.shoppingCart.products)
  const shoppingCartAddresses = useAppSelector((state) => state.shoppingCart.addresses)

  const dispatch = useAppDispatch()

  const shoppingCartProductsWithQuantity = shoppingCartProducts.map(shoppingCartProduct => ({ ...shoppingCartProduct, quantity: shoppingCartProduct.quantity || 1 }))

  const hasArticleBeenAssigned = (articleId: string) => {
    return shoppingCartAddresses
      .flatMap(address => address.assignedProducts)
      .some(article => article.id === articleId)
  }

  return (
    <div>
      <div className="row justify-content-center m-0">
        <div className="col-lg-7 col-md-10 col-sm-11">
          <div className="horizontal-steps mt-4 mb-4 pb-5" id="tooltip-container">
            <div className="horizontal-steps-content">
              <div
                className="step-item"
              >
                <span
                  data-bs-container="#tooltip-container"
                  data-bs-toggle="tooltip"
                  data-bs-placement="bottom"
                  title=""
                  data-bs-title=""
                >
                  Your Cart
                </span>
              </div>
              <div className="step-item current">
                <span
                  data-bs-container="#tooltip-container"
                  data-bs-toggle="tooltip"
                  data-bs-placement="bottom"
                  title=""
                  data-bs-title=""
                >
                  Shipping Details
                </span>
              </div>
              <div
                className="step-item"
              >
                <span>Payment</span>
              </div>
            </div>
            <div className="process-line" style={{ width: '50%' }}></div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-xl-6 mb-4">
          <h6 className="fw-bold">Order Summary</h6>
          <div className="table-responsive border border-bottom-0 rounded">
            <table className="table align-middle mb-0">
              <thead>
                <tr>
                  <th scope="col">Products</th>
                  <th scope="col">Quantity</th>
                  <th scope="col" className="text-nowrap text-end">Total Price</th>
                </tr>
              </thead>
              <tbody>
                {
                  shoppingCartProductsWithQuantity.length > 0
                    ? (
                        shoppingCartProductsWithQuantity.map(shoppingCartProduct => (
                          <tr key={`shipping-cart-product${shoppingCartProduct.id}`}>
                            <td scope="row">{shoppingCartProduct.name} <br /><span className={`small badge rounded-pill ${hasArticleBeenAssigned(shoppingCartProduct.id) ? 'text-bg-success' : 'text-bg-warning'}`}>{hasArticleBeenAssigned(shoppingCartProduct.id) ? 'assigned' : 'unassigned'}</span></td>
                            <td>{shoppingCartProduct.quantity}</td>
                            <td className="text-end">
                              {formatPrice('EUR', navigator.language).format(calculateGraduatedPrice(shoppingCartProduct.graduatedPrices, shoppingCartProduct.quantity, shoppingCartProduct.netRetailPrice.amount) * shoppingCartProduct.quantity)}
                            </td>
                          </tr>
                        ))
                      )
                    : (
                      <tr className="text-center">
                        <td colSpan={4}>No item has been added to your cart.</td>
                      </tr>
                      )
                }
              </tbody>
            </table>
          </div>
        </div>
        <div className="col">
          <h6 className="fw-bold">Shipping</h6>
          <div className="border p-2 rounded">
            <div className="row mb-4">
              <div className="col">
                <h6>1. Choose a shipping location</h6>
                <div className="form-check my-3">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="inventoryRadio"
                    onChange={(event) => {
                      const value = event.target.value
                      if (value === 'on') {
                        setShippingMode('inventory')
                      }
                    }}
                    checked={shippingMode === 'inventory'}
                    disabled
                  />
                  <label className="form-check-label" htmlFor="inventoryRadio">
                    Ship articles to inventory
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="deliveryRadio"
                    onChange={(event) => {
                      const value = event.target.value
                      if (value === 'on') {
                        setShippingMode('delivery')
                      }
                    }}
                    checked={shippingMode === 'delivery'}
                  />
                  <label className="form-check-label" htmlFor="deliveryRadio">
                    Ship articles to delivery address
                  </label>
                </div>
              </div>
            </div>
            {
              shippingMode === 'delivery' && (
                <div className="row">
                  <div className="col">
                    <h6>2. Select delivery address</h6>
                    <p className="small mb-0">Select an address from the address book in order to assign articles.</p>
                    <button type="button"
                      className="btn btn-primary my-2"
                      onClick={() => {
                        openModal('shoppingDeliveryAddressPickerModal')
                        dismissModal('shoppingShippingDetailsModal')
                      }}
                    >
                      <i className="bi bi-plus-circle me-1"></i>Add Delivery Address
                    </button>
                    <div className="accordion-custom order-cart mt-2" id="accordionFlushShippingDetails">
                      {
                        shoppingCartAddresses.length > 0
                          ? (
                              shoppingCartAddresses.map((shoppingCartAddress, index) => (
                                <div key={`shopping-shipping-details-${shoppingCartAddress.id}`} className="accordion-item">
                                  <h2 className="accordion-header">
                                    <div className="d-flex">
                                      <div className="w-100">
                                        <button
                                          className="accordion-button collapsed"
                                          type="button"
                                          data-bs-toggle="collapse"
                                          data-bs-target={`#collapse-${index}`}
                                          aria-expanded="false"
                                          aria-controls={`collapse-${index}`}
                                        >
                                          <span className="small">
                                            {generateAddressText(shoppingCartAddress)}
                                          </span>
                                        </button>
                                      </div>
                                      <div className="flex-shrink-1 d-flex align-items-center">
                                        <button
                                          className="btn btn-outline-secondary text-nowrap btn-sm m-2"
                                          type="button"
                                          onClick={() => {
                                            dismissModal('shoppingShippingDetailsModal')
                                            openModal('shoppingDetailsAssignArticlesModal')
                                            setSelectedAssignmentAddress(shoppingCartAddress)
                                          }}
                                        >
                                          Assign Articles
                                        </button>
                                      </div>
                                    </div>
                                  </h2>
                                  <div id={`collapse-${index}`} className="accordion-collapse collapse">
                                    <div className="accordion-body">
                                      <div className="mb-3">
                                        <button
                                          className="btn btn-outline-danger text-nowrap btn-sm"
                                          type="button"
                                          onClick={() => {
                                            const foundShoppingAddressIndex = shoppingCartAddresses.findIndex(cartAddress => cartAddress.id === shoppingCartAddress.id)

                                            if (foundShoppingAddressIndex !== -1) {
                                              const updatedAddressesInCart = shoppingCartAddresses.filter(cartAddress => cartAddress.id !== shoppingCartAddress.id)

                                              const payload = {
                                                addresses: updatedAddressesInCart
                                              }
                                              dispatch(setShoppingAddresses(payload))
                                              setSelectedAssignmentAddress(null)
                                            }
                                          }}
                                        >
                                          Remove Address
                                        </button>
                                      </div>
                                      <div className="table-responsive border border-bottom-0 rounded">
                                        <table className="table align-middle mb-0">
                                          <thead>
                                            <tr>
                                              <th scope="col">Assigned Articles</th>
                                              <th scope="col" className="">Quantity</th>
                                              <th scope="col" className="text-nowrap text-end">Actions</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {
                                              shoppingCartAddress?.assignedProducts.length > 0
                                                ? (
                                                    shoppingCartAddress.assignedProducts.map(assignedProduct => (
                                                      <tr key={`shopping-details-assigned-product-${assignedProduct.id}`}>
                                                        <td scope="row">{assignedProduct.name}</td>
                                                        <td>{assignedProduct.quantity}</td>
                                                        <td className="text-end">
                                                          <div className="d-flex flex-row float-end">
                                                            <button
                                                              type="button"
                                                              title="Remove Assigned Article"
                                                              className="btn btn-outline-danger btn-round"
                                                              onClick={() => {
                                                                const foundShoppingAddressIndex = shoppingCartAddresses.findIndex(cartAddress => cartAddress.id === shoppingCartAddress.id)

                                                                if (foundShoppingAddressIndex !== -1) {
                                                                  const updatedAddressesInCart = [...shoppingCartAddresses]
                                                                  const updatedSelectedAssignmentAddress = updatedAddressesInCart[foundShoppingAddressIndex]
                                                                  const assignedProducts = updatedSelectedAssignmentAddress.assignedProducts

                                                                  const updatedAssignedProducts = assignedProducts.filter(product => product.id !== assignedProduct.id)

                                                                  updatedAddressesInCart[foundShoppingAddressIndex] = {
                                                                    ...updatedAddressesInCart[foundShoppingAddressIndex],
                                                                    assignedProducts: updatedAssignedProducts
                                                                  }

                                                                  const payload = {
                                                                    addresses: updatedAddressesInCart
                                                                  }
                                                                  dispatch(setShoppingAddresses(payload))
                                                                  setSelectedAssignmentAddress(updatedAddressesInCart[foundShoppingAddressIndex])
                                                                }
                                                              }}
                                                            >
                                                              <TrashIcon />
                                                            </button>
                                                          </div>
                                                        </td>
                                                      </tr>
                                                    ))
                                                  )
                                                : (
                                                  <tr className="text-center">
                                                    <td colSpan={4}>No product has been assigned to this address yet.</td>
                                                  </tr>
                                                  )
                                            }
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))
                            )
                          : (
                            <div className="accordion-item">
                              <h2 className="accordion-header">
                                <div className="d-flex">
                                  <div className="w-100">
                                    <button
                                      className="accordion-button collapsed"
                                      type="button"
                                      data-bs-toggle="collapse"
                                      data-bs-target="#collapse-none"
                                      aria-expanded="false"
                                      aria-controls="collapse-none"
                                    >
                                      <span className="small">
                                      No delivery addresses selected
                                      </span>
                                    </button>
                                  </div>
                                  <div className="flex-shrink-1 d-flex align-items-center">
                                    <button
                                      className="btn btn-outline-secondary text-nowrap btn-sm m-2"
                                      type="button"
                                      disabled
                                    >
                                      Assign Articles
                                    </button>
                                  </div>
                                </div>
                              </h2>
                              <div id="collapse-none" className="accordion-collapse collapse">
                                <div className="accordion-body">
                                  <span className="small">Click the <span className="fw-semibold">&apos;Add Delivery Address&apos;</span> button to add addresses</span>
                                </div>
                              </div>
                            </div>
                            )
                      }
                    </div>
                  </div>
                </div>
              )
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default ShoppingShippingDetails
