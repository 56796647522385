import React from 'react'
import { ShoppingCartAddress } from '../../types'
import { generateAddressText } from '../../utils/generateAddressText'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { TrashIcon } from '../icons/TrashIcon'
import { setShoppingAddresses } from '../../store/reducers/shoppingCartReducer'

interface ShoppingDetailsAssignArticlesProps {
  selectedAssignmentAddress: ShoppingCartAddress
  setSelectedAssignmentAddress: React.Dispatch<React.SetStateAction<ShoppingCartAddress | null>>
}
const ShoppingDetailsAssignArticles = ({ selectedAssignmentAddress, setSelectedAssignmentAddress }: ShoppingDetailsAssignArticlesProps) => {
  const shoppingCartProducts = useAppSelector((state) => state.shoppingCart.products)
  const shoppingCartAddresses = useAppSelector((state) => state.shoppingCart.addresses)

  const shoppingCartProductsWithQuantity = shoppingCartProducts.map(shoppingCartProduct => ({ ...shoppingCartProduct, quantity: shoppingCartProduct.quantity || 1 }))

  const dispatch = useAppDispatch()

  const hasArticleBeenAssigned = (articleId: string) => {
    return shoppingCartAddresses
      .flatMap(address => address.assignedProducts)
      .some(article => article.id === articleId)
  }

  return (
    <div>
      <div className="row">
        <div className="my-2">
          <h6>Assign articles to address: <span className="text-primary">{generateAddressText(selectedAssignmentAddress)}</span></h6>
        </div>
        <div className="col">
          <h5>Add Shopping Cart Articles</h5>
          <div className="table-responsive border border-bottom-0 rounded">
            <table className="table align-middle mb-0">
              <thead>
                <tr>
                  <th scope="col">Products</th>
                  <th scope="col" className="col-2">Quantity</th>
                  <th scope="col" className="text-nowrap text-end col-1">Actions</th>
                </tr>
              </thead>
              <tbody>
                {
                  shoppingCartProductsWithQuantity.length > 0
                    ? (
                        shoppingCartProductsWithQuantity.map(shoppingCartProduct => (
                          <tr key={`shipping-cart-product${shoppingCartProduct.id}`}>
                            <td scope="row">{shoppingCartProduct.name}</td>
                            <td>{shoppingCartProduct.quantity}</td>
                            <td className="text-end">
                              <div className="d-flex flex-row float-end">
                                <button
                                  type="button"
                                  title="Add Article to Address"
                                  className="btn btn-outline-primary btn-round"
                                  disabled={hasArticleBeenAssigned(shoppingCartProduct.id)}
                                  onClick={() => {
                                    const foundShoppingAddressIndex = shoppingCartAddresses.findIndex(shoppingCartAddress => shoppingCartAddress.id === selectedAssignmentAddress.id)

                                    if (foundShoppingAddressIndex !== -1) {
                                      const updatedAddressesInCart = [...shoppingCartAddresses]
                                      const updatedSelectedAssignmentAddress = updatedAddressesInCart[foundShoppingAddressIndex]
                                      const assignedProducts = updatedSelectedAssignmentAddress.assignedProducts

                                      const foundAssignedProduct = assignedProducts.find(assignedProduct => assignedProduct.id === shoppingCartProduct.id)
                                      if (foundAssignedProduct === undefined) {
                                        const updatedAssignedProducts = [...assignedProducts, shoppingCartProduct]
                                        updatedAddressesInCart[foundShoppingAddressIndex] = {
                                          ...updatedAddressesInCart[foundShoppingAddressIndex],
                                          assignedProducts: updatedAssignedProducts
                                        }
                                        const payload = {
                                          addresses: updatedAddressesInCart
                                        }
                                        dispatch(setShoppingAddresses(payload))
                                        setSelectedAssignmentAddress(updatedAddressesInCart[foundShoppingAddressIndex])
                                      }
                                    }
                                  }}
                                >
                                  <i className="bi bi-plus-circle-fill"></i>
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))
                      )
                    : (
                      <tr className="text-center">
                        <td colSpan={4}>No item has been added to your cart.</td>
                      </tr>
                      )
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="row my-4">
        <div className="col">
          <div className="table-responsive border border-bottom-0 rounded">
            <table className="table align-middle mb-0">
              <thead>
                <tr>
                  <th scope="col">Assigned Articles</th>
                  <th scope="col" className="col-2">Quantity</th>
                  <th scope="col" className="text-nowrap text-end col-1">Actions</th>
                </tr>
              </thead>
              <tbody>
                {
                  selectedAssignmentAddress?.assignedProducts.length > 0
                    ? (
                        selectedAssignmentAddress.assignedProducts.map(assignedProduct => (
                          <tr key={`shopping-details-assigned-product-${assignedProduct.id}`}>
                            <td scope="row">{assignedProduct.name}</td>
                            <td>{assignedProduct.quantity}</td>
                            <td className="text-end">
                              <div className="d-flex flex-row float-end">
                                <button
                                  type="button"
                                  title="Remove Assigned Article"
                                  className="btn btn-outline-danger btn-round"
                                  onClick={() => {
                                    const foundShoppingAddressIndex = shoppingCartAddresses.findIndex(shoppingCartAddress => shoppingCartAddress.id === selectedAssignmentAddress.id)

                                    if (foundShoppingAddressIndex !== -1) {
                                      const updatedAddressesInCart = [...shoppingCartAddresses]
                                      const updatedSelectedAssignmentAddress = updatedAddressesInCart[foundShoppingAddressIndex]
                                      const assignedProducts = updatedSelectedAssignmentAddress.assignedProducts

                                      const updatedAssignedProducts = assignedProducts.filter(product => product.id !== assignedProduct.id)

                                      updatedAddressesInCart[foundShoppingAddressIndex] = {
                                        ...updatedAddressesInCart[foundShoppingAddressIndex],
                                        assignedProducts: updatedAssignedProducts
                                      }

                                      const payload = {
                                        addresses: updatedAddressesInCart
                                      }
                                      dispatch(setShoppingAddresses(payload))
                                      setSelectedAssignmentAddress(updatedAddressesInCart[foundShoppingAddressIndex])
                                    }
                                  }}
                                >
                                  <TrashIcon />
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))
                      )
                    : (
                      <tr className="text-center">
                        <td colSpan={4}>No product has been assigned to this address yet.</td>
                      </tr>
                      )
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ShoppingDetailsAssignArticles
