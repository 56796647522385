import { Formik } from 'formik'
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { object, string } from 'yup'
import { useNavigate } from 'react-router-dom'
import Select, { CSSObjectWithLabel } from 'react-select'
import dayjs from 'dayjs'
import Pagination from '../../components/Pagination'
import useDebounce from '../../utils/hooks/useDebounce'
import { useAppSelector, useAppDispatch } from '../../store/hooks'
import { getAllCatalogueProducts, resetProductError } from '../../store/reducers/api/productReducer'
import * as userRoles from '../../constants/userRoles'
import { getCompanyLegalTexts } from '../../store/reducers/api/companyReducer'
import ShopItemLoader from '../../components/Shop/ShopItemLoader'
import { getProductCategories } from '../../store/reducers/api/productCategoryReducer'
import ProductCategoryLoader from '../../components/Shop/ProductCategoryLoader'
import Placeholder from '../../assets/images/placeholder.png'
import { setToast } from '../../store/reducers/toastReducer'
import { formatPrice } from '../../utils/formatPrice'
import { setShoppingCart } from '../../store/reducers/shoppingCartReducer'
import ShoppingModals from '../../components/Shop/ShoppingModals'
import { Address, Module, NetRetailPrice, Permission, ProductGraduatedPrice } from '../../types'
import { loadCostCenterOptions } from '../../utils/loadCostCenterOptions'
import { debounce } from '../../utils/debounce'
import hasPermission from '../../utils/checkPermissions'
import { READ } from '../../constants/permissions'
import * as appModules from '../../constants/appModules'
import { getAllSalutations } from '../../store/reducers/api/salutationReducer'
import { capitalizeWords } from '../../utils/capitalizeWords'
import CatalogueHeader from '../../assets/images/Header-Shop_var2.jpg'
import { getProductColors } from '../../store/reducers/api/productColorReducer'
import { getProductMaterials } from '../../store/reducers/api/productMaterialReducer'
import { getMinimumProductOrderQuantity } from '../../utils/getMinimumProductOrderQuantity'

const allProductsImageUrl = process.env.REACT_APP_ALL_PRODUCTS_IMAGE_URL || 'https://storage.googleapis.com/endeavor-b285f.appspot.com/products/categories/all-products.jpg'

const Shop = () => {
  const currentUser = useAppSelector((state) => state.apiAuth.currentUser)
  const profile = useAppSelector((state) => state.profile.profile)
  const isLoading = useAppSelector((state) => state.apiProduct.isLoading)
  const products = useAppSelector((state) => state.apiProduct.catalogueProducts)
  const metadata = useAppSelector((state) => state.apiProduct.metadata)
  const error = useAppSelector((state) => state.apiProduct.error)
  const productCategories = useAppSelector((state) => state.apiProductCategory.productCategories)
  const isLoadingProductCategories = useAppSelector((state) => state.apiProductCategory.isLoading)
  const productCategoriesError = useAppSelector((state) => state.apiProductCategory.error)
  const shoppingCartProducts = useAppSelector((state) => state.shoppingCart.products)
  const productColors = useAppSelector((state) => state.apiProductColor.productColors)
  const isLoadingProductColors = useAppSelector((state) => state.apiProductColor.isLoading)
  const productMaterials = useAppSelector((state) => state.apiProductMaterial.productMaterials)
  const isLoadingProductMaterials = useAppSelector((state) => state.apiProductMaterial.isLoading)

  const companyId = profile?.company?.id
  const role = profile?.role || userRoles.USER
  const token = currentUser?.token
  const shoppingCartProductsWithQuantity = shoppingCartProducts.map(shoppingCartProduct => ({ ...shoppingCartProduct, quantity: shoppingCartProduct.quantity || getMinimumProductOrderQuantity((shoppingCartProduct.minimumOrderQuantity ?? 1), shoppingCartProduct.graduatedPrices) }))
  const userId = profile?.id
  const companyOwnerId = profile?.company?.owner?.id
  const isOwner = companyOwnerId && userId === companyOwnerId

  const companyAccessPermissions = profile?.company?.accessPermissions || []
  const defaultAccessPermissions = profile?.company?.defaultAccessPermissions || []

  const isAllowed = (module: Module, permission: Permission = READ) => {
    return isOwner || hasPermission(module, role, companyAccessPermissions, defaultAccessPermissions, permission)
  }

  const dispatch = useAppDispatch()

  const [perPage, setPerPage] = useState(20)
  const [page, setPage] = useState(1)

  const [searchTerm, setSearchTerm] = useState<string>('')
  const debouncedSearchTerm: string = useDebounce<string>(searchTerm, 800)
  const [orderBy, setOrderBy] = useState<string | undefined>('')
  const [filterCategories, setFilterCategories] = useState<string[]>([])
  const [filterCategoryTags, setFilterCategoryTags] = useState<string[]>([])
  const [filterPriceRanges, setFilterPriceRanges] = useState<string[]>([])
  const [filterColor, setFilterColor] = useState<string | undefined>(undefined)
  const [filterMaterial, setFilterMaterial] = useState<string | undefined>(undefined)
  const [selectedAddress, setSelectedAddress] = useState<Partial<Address> | null>(null)
  const [showChildren] = useState(false)

  const handleShowEntries = (event: ChangeEvent<HTMLSelectElement>) => {
    setPerPage(Number(event.target.value))
  }

  const handlePageChange = (page: number) => {
    setPage(page)
  }

  const navigate = useNavigate()

  const searchSchema = object({
    search: string()
      .max(24, 'Search Name is too long')
  })

  const orderBySchema = object({
    orderBy: string().oneOf(['name:asc', 'name:desc', 'price:asc', 'price:desc'])
  })

  const filterByCategorySchema = object({
    filter: string()
  })

  const placeHolderStyles = {
    textOverflow: 'ellipsis',
    maxWidth: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    display: 'initial'
  }

  const categoryOptions = productCategories.map(productCategory => ({
    value: productCategory.name,
    label: productCategory.name,
    tags: productCategory.productCategoryTags.map(productCategoryTag => ({
      value: productCategoryTag.id,
      label: productCategoryTag.name
    })),
    picture: productCategory.picture
  }))

  const filterPriceOptions = [
    {
      value: 'price',
      label: 'Price',
      options: [
        {
          value: '0-10',
          label: '0 - 10 EUR'
        },
        {
          value: '11-25',
          label: '11 - 25 EUR'
        },
        {
          value: '26-100',
          label: '26 - 100 EUR'
        },
        {
          value: '101-500',
          label: '101 - 500 EUR'
        },
        {
          value: '501-2000',
          label: '501 - 2000 EUR'
        }
      ]
    }
  ]

  const filterColorOptions = productColors.map(color => ({
    value: color.name,
    label: capitalizeWords(color.name),
    code: color.hexCode
  }))
  const filterMaterialOptions = productMaterials.map(material => ({
    value: material.name,
    label: capitalizeWords(material.name)
  }))

  const loadCostCenterOptionsDebounced = useCallback(
    debounce((inputValue: string, callback: (options: { value: string, label: string }[]) => void) => {
      companyId &&
      loadCostCenterOptions(companyId, 1, 1000, String(token), inputValue, isAllowed(appModules.COSTCENTERS))
        .then(options => callback(options))
    }, 800),
    []
  )

  const renderPrice = (graduatedPrices: ProductGraduatedPrice[], netRetailPrice: NetRetailPrice) => {
    if (graduatedPrices.length > 0) {
      const lowestPrice = graduatedPrices.reduce((min, item) => item.price < min ? item.price : min, graduatedPrices[0].price)
      return `from ${formatPrice('EUR', navigator.language).format(lowestPrice)}`
    } else {
      return formatPrice('EUR', navigator.language).format(netRetailPrice.amount)
    }
  }

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal
    const perPage = 12
    const page = 1
    if (token) {
      dispatch(getProductCategories({ token, perPage, page, signal }))
    }

    return () => {
      controller.abort()
    }
  }, [])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal
    const perPage = 100
    const page = 1
    if (token) {
      dispatch(getProductColors({ token, perPage, page, signal }))
    }

    return () => {
      controller.abort()
    }
  }, [])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal
    const perPage = 100
    const page = 1
    if (token) {
      dispatch(getProductMaterials({ token, perPage, page, signal }))
    }

    return () => {
      controller.abort()
    }
  }, [])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal
    const filter = 'filter[type]=terms'

    if (companyId && token && isAllowed(appModules.LEGALTEXTS)) {
      dispatch(getCompanyLegalTexts({ id: companyId, token, perPage: 10, page: 1, filter, signal }))
    }

    return () => {
      controller.abort()
    }
  }, [companyId])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    if (token) {
      dispatch(getAllSalutations({ token, perPage: 50, page: 1, signal }))
    }

    return () => {
      controller.abort()
    }
  }, [])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal
    let filter = filterCategories.length > 0 ? `filter[category]=${filterCategories.join(',')}` : ''
    if (filterCategoryTags.length > 0) {
      filter = filter + `&filter[tags]=${filterCategoryTags.join(',')}`
    }
    if (filterPriceRanges.length) {
      filter = filter + `&filter[price]=${filterPriceRanges.join(',')}`
    }
    if (filterColor) {
      filter = filter + `&filter[color]=${filterColor}`
    }
    if (filterMaterial) {
      filter = filter + `&filter[material]=${filterMaterial}`
    }
    filter = filter + `&filter[showChildren]=${showChildren}`

    if (token) {
      dispatch(getAllCatalogueProducts({ token, perPage, page, search: debouncedSearchTerm, signal, orderBy, filter }))
    }

    return () => {
      controller.abort()
    }
  }, [perPage, page, debouncedSearchTerm, orderBy, filterCategories, filterCategoryTags, filterPriceRanges, filterColor, filterMaterial])

  useEffect(() => {
    if ((error && error.errors) || (productCategoriesError && productCategoriesError.errors)) {
      const payload = {
        title: 'Error',
        message: error?.errors?.message || productCategoriesError?.errors?.message,
        isVisible: true,
        timestamp: dayjs().format('LT'),
        type: 'danger'
      }
      dispatch(setToast(payload))
      dispatch(resetProductError())
    }
  }, [error, productCategoriesError])

  return (
    <main>
      <div className="container-fluid px-4 py-4">
        <div className="row">
          <div className="col">
            <div className="card overflow-hidden">
              <img src={CatalogueHeader} className="card-img rounded-0" alt="Shop Banner" />
              <div className="card-img-overlay">
                <h1 className="card-title"></h1>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="text-center mt-4">
            <div className="row row-cols-2 row-cols-sm-2 row-cols-md-6 gy-2">
              {
                isLoadingProductCategories
                  ? (<ProductCategoryLoader repetition={5} />)
                  : (
                      categoryOptions.length > 0
                        ? (
                            categoryOptions.slice(0, 5).map(option => (
                              <div key={option.value} className="col">
                                <div
                                  className="card square-container"
                                  role="button"
                                  tabIndex={0}
                                  onClick={() => {
                                    setFilterCategories([option.value])
                                    if (page !== 1) {
                                      setPage(1)
                                      navigate('')
                                    }
                                  }}
                                >
                                  <div>
                                    <img className="square-image text-center h-100"
                                      src={(option.picture && option.picture.url) ? option.picture.url : Placeholder}
                                      alt="Category Preview"
                                      onError={(e) => {
                                        const target = e.target as HTMLImageElement
                                        target.onerror = null
                                        target.src = Placeholder
                                      }}
                                    />
                                    <div style={{ position: 'absolute', top: '70%', left: '50%', transform: 'translate(-50%, -50%)', opacity: 0.7 }}>
                                      <span className="small text-capitalize d-flex flex-column align-items-end">
                                        <span className="text-bg-light p-1 rounded">
                                          {option.label.replace('and', '&')}
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))
                          )
                        : (
                          <div className="col">
                            <div className="card py-4 px-1 h-100">
                              <span className="small text-capitalize">No category has been added yet</span>
                            </div>
                          </div>
                          )
                    )
              }
              <div className="col">
                <div
                  className="card square-container"
                  role="button"
                  tabIndex={0}
                  onClick={() => {
                    setFilterCategories([])
                    if (page !== 1) {
                      setPage(1)
                      navigate('')
                    }
                  }}
                >
                  <div>
                    <img
                      className="square-image text-center h-100"
                      src={allProductsImageUrl}
                      alt="Category Preview"
                      onError={(e) => {
                        const target = e.target as HTMLImageElement
                        target.onerror = null
                        target.src = Placeholder
                      }}
                    />
                    <div style={{ position: 'absolute', top: '70%', left: '50%', transform: 'translate(-50%, -50%)', opacity: 0.7 }}>
                      <span className="small text-capitalize d-flex flex-column align-items-end">
                        <span className="text-bg-light p-1 rounded">
                          All Products
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="">
            <div className="navbar navbar-expand mt-3">
              <ul className="navbar-nav ms-auto me-0 me-md-0 my-0 my-md-0">
                <div className="ms-auto me-1 me-md-3">
                  <Formik
                    validationSchema={orderBySchema}
                    enableReinitialize
                    initialValues={{
                      orderBy: ''
                    }}
                    onSubmit={({ orderBy }, actions) => {
                      actions.setSubmitting(false)
                    }}
                  >
                    {({
                      values,
                      handleChange,
                      handleSubmit
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          <label htmlFor="inputOrderBy" className="col-3 col-form-label">Sort:</label>
                          <div className="col-9">
                            <select
                              className="form-select"
                              id="inputOrderBy"
                              name="orderBy"
                              aria-label="Sort by"
                              onChange={(event) => {
                                handleChange(event)
                                const value = event.target.value
                                if (value !== '') {
                                  const [orderKey, direction] = value.split(':')
                                  setOrderBy(`orderBy[${orderKey}]=${direction}`)
                                } else {
                                  setOrderBy('')
                                }
                              }}
                              value={values.orderBy}
                            >
                              <option value="">Sort By</option>
                              <option value="name:asc">Name Ascending</option>
                              <option value="name:desc">Name Descending</option>
                              <option value="price:asc">Price Ascending</option>
                              <option value="price:desc">Price Descending</option>
                            </select>
                          </div>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
                <div className="d-none d-md-inline-block form-inline ms-auto me-0 me-md-3 my-2 my-md-0">
                  <Formik
                    validationSchema={searchSchema}
                    enableReinitialize
                    initialValues={{
                      search: ''
                    }}
                    onSubmit={({ search }, actions) => {
                      actions.setSubmitting(false)
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="input-group">
                          <input
                            onChange={(event) => {
                              const search = event.target.value
                              handleChange(event)
                              setSearchTerm(search)
                              if (page !== 1) {
                                setPage(1)
                                navigate('')
                              }
                            }}
                            maxLength={24}
                            onBlur={handleBlur}
                            value={values.search}
                            className={`form-control ${
                              errors.search &&
                              touched.search &&
                              errors.search
                                ? 'is-invalid'
                                : ''
                            }`}
                            type="text"
                            placeholder="Search..."
                            aria-label="Search"
                            aria-describedby="btnNavbarShopSearch"
                            name="search"
                            autoComplete="on"
                          />
                          <button
                            className="btn btn-outline-dark"
                            id="btnNavbarShopSearch"
                            type="submit"
                            disabled={isSubmitting}
                          >
                            <i className="fas fa-search"></i>
                          </button>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
                <button title="Open Cart" className="btn btn-outline-primary text-nowrap" data-bs-toggle="modal" data-bs-target="#shoppingCartModal">
                  <i className="bi bi-cart me-1"></i>Cart <span className="badge text-bg-danger">{shoppingCartProductsWithQuantity.length}</span>
                </button>
              </ul>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-xl-2">
            <div className="row">
              <Formik
                validationSchema={filterByCategorySchema}
                enableReinitialize
                initialValues={{
                  filter: []
                }}
                onSubmit={({ filter }, actions) => {
                  actions.setSubmitting(false)
                }}
              >
                {({
                  handleSubmit
                }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="col-12">
                      <h5>Category</h5>
                      <div className="row row-cols-2 row-cols-lg-3 row-cols-xl-1 g-2 g-lg-3 mb-2">
                        <div className="col">
                          <Select
                            name="categories"
                            options={[{ label: capitalizeWords('all products'), value: '' }]}
                            placeholder={capitalizeWords('all products')}
                            styles={{
                              control: (provided, state) => ({
                                ...provided
                              }),
                              placeholder: (provided, state) => ({
                                ...provided,
                                ...placeHolderStyles as CSSObjectWithLabel
                              })
                            }}
                            onChange={(newValue) => {
                              setFilterCategories([])
                              if (page !== 1) {
                                setPage(1)
                                navigate('')
                              }
                            }}
                            isClearable
                          />
                        </div>
                        {
                          categoryOptions.map((option: any) => {
                            const mappedOptions: Array<{ label: string, value: string }> = option.tags && option.tags.length > 0 ? option.tags : [{ label: capitalizeWords(option.label.replace('and', '&')), value: option.value }]
                            const isMulti = !!(option.tags && option.tags.length > 0)
                            return (
                              <div key={option.value} className="col">
                                <Select
                                  isMulti={isMulti}
                                  name="categories"
                                  options={mappedOptions}
                                  placeholder={capitalizeWords(option.label.replace('and', '&'))}
                                  styles={{
                                    control: (provided, state) => ({
                                      ...provided
                                    }),
                                    placeholder: (provided, state) => ({
                                      ...provided,
                                      ...placeHolderStyles as CSSObjectWithLabel
                                    })
                                  }}
                                  isClearable
                                  onChange={(newValue) => {
                                    if (page !== 1) {
                                      setPage(1)
                                      navigate('')
                                    }
                                    if (!isMulti) {
                                      if (newValue) {
                                        const selectedCategory = newValue as { label: string, value: string }
                                        const foundCategory = filterCategories.find(category => category === selectedCategory.value)
                                        if (!foundCategory) {
                                          const updatedCategories = filterCategories.concat(selectedCategory.value)
                                          setFilterCategories(updatedCategories)
                                        }
                                      } else {
                                        setFilterCategories(filterCategories.filter(category => category !== mappedOptions[0].value))
                                      }
                                    } else {
                                      const selectedCategoryTags = (newValue as Array<{ label: string, value: string }>)
                                      const mappedOptionsValues = mappedOptions.map(mappedOption => mappedOption.value)
                                      const categoryTagsToAdd = selectedCategoryTags
                                        .filter(tag => !filterCategoryTags.includes(tag.value))
                                        .map(tag => tag.value)
                                      const categoryTagsToRemove = mappedOptionsValues
                                        .filter(mappedOption => !(selectedCategoryTags.map(tag => tag.value)).includes(mappedOption))

                                      const updatedCategoryTags = filterCategoryTags.concat(categoryTagsToAdd).filter(tag => !categoryTagsToRemove.includes(tag))
                                      setFilterCategoryTags(updatedCategoryTags)
                                    }
                                  }}
                                />
                              </div>
                            )
                          })
                        }
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
              <div className="col-12">
                <h5>Filter</h5>
                <div className="row row-cols-3 row-cols-lg-3 row-cols-xl-1 g-2 g-lg-3 mb-4">
                  {
                    filterPriceOptions.map((option: any) => {
                      const mappedOptions = option.options && option.options.length > 0 ? option.options : [{ label: option.label, value: option.value }]
                      return (
                        <div key={option.value} className="col">
                          <Select
                            isMulti
                            name="filters"
                            options={mappedOptions}
                            placeholder={option.label}
                            styles={{
                              control: (provided, state) => ({
                                ...provided
                              }),
                              placeholder: (provided, state) => ({
                                ...provided,
                                ...placeHolderStyles as CSSObjectWithLabel
                              })
                            }}
                            isClearable
                            onChange={(newValue) => {
                              const selectedPriceRanges = (newValue as Array<{ label: string, value: string }>)
                              const mappedOptionsValues = selectedPriceRanges.map(selectedPriceRange => selectedPriceRange.value)
                              setFilterPriceRanges(mappedOptionsValues)
                              if (page !== 1) {
                                setPage(1)
                                navigate('')
                              }
                            }}
                          />
                        </div>
                      )
                    })
                  }
                  <div className="col">
                    <Select
                      name="filters"
                      options={filterColorOptions}
                      placeholder="Color"
                      styles={{
                        control: (provided, state) => ({
                          ...provided
                        }),
                        placeholder: (provided, state) => ({
                          ...provided,
                          ...placeHolderStyles as CSSObjectWithLabel
                        })
                      }}
                      isClearable
                      onChange={(newValue) => {
                        setFilterColor(newValue?.value)
                        if (page !== 1) {
                          setPage(1)
                          navigate('')
                        }
                      }}
                      formatOptionLabel={(data) => (
                        <div className="d-flex flex-row align-items-center">
                          <div
                            className="me-2 border rounded-circle"
                            style={{
                              width: '20px',
                              height: '20px',
                              backgroundColor: data.code
                            }}
                          >
                          </div>
                          <div>{data.label}</div>
                        </div>
                      )}
                      isLoading={isLoadingProductColors}
                    />
                  </div>
                  <div className="col">
                    <Select
                      name="filters"
                      options={filterMaterialOptions}
                      placeholder="Material"
                      styles={{
                        control: (provided, state) => ({
                          ...provided
                        }),
                        placeholder: (provided, state) => ({
                          ...provided,
                          ...placeHolderStyles as CSSObjectWithLabel
                        })
                      }}
                      isClearable
                      onChange={(newValue) => {
                        setFilterMaterial(newValue?.value)
                        if (page !== 1) {
                          setPage(1)
                          navigate('')
                        }
                      }}
                      isLoading={isLoadingProductMaterials}
                    />
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div className="col-12 col-xl-10 mt-2">
            {
              isLoading
                ? (
                  <div className="row row-cols-1 row-cols-sm-2 row-cols-md-4 row-cols-xl-4 row-cols-xxl-5 gy-4">
                    <ShopItemLoader repetition={perPage} />
                  </div>
                  )
                : products.length > 0
                  ? <div className="row row-cols-1 row-cols-sm-2 row-cols-md-4 row-cols-xl-4 row-cols-xxl-5 gy-4">
                  {
                    (
                      products.map((product) => (
                        <div className="col" key={product.id}>
                          <div
                            aria-label={product.name}
                            className="card h-100 product-card"
                          >
                            <img
                              src={product.pictures && product.pictures?.length > 0 ? product.pictures[0].publicUrl : Placeholder}
                              alt="Product thumbnail"
                              className={`card-img-top ${product.pictures && product.pictures?.length > 0 ? 'object-fit-contain object-fit-md-contain' : 'object-fit-cover'}`}
                              role="button"
                              style={{
                                height: 300
                              }}
                              tabIndex={0}
                              onClick={() => {
                                navigate(`/shop/${product.id}`)
                              }}
                              onError={(e) => {
                                const target = e.target as HTMLImageElement
                                target.onerror = null
                                target.src = Placeholder
                              }}
                            />
                            <div
                              className="h-100 d-flex align-content-end flex-wrap"
                              role="button"
                              tabIndex={0}
                              onClick={() => {
                                navigate(`/shop/${product.id}`)
                              }}
                            >
                              <div className="card-body p-2">
                                <h6 className="card-title text-break">{product.name}</h6>
                                <div className="d-flex justify-content-between border-top">
                                  <span className="card-text small fw-semibold text-muted">
                                    {/* {product.productCategory?.name} */}
                                  </span>
                                  <span className="card-text fw-semibold text-muted">{renderPrice(product.graduatedPrices, product.netRetailPrice)}</span>
                                </div>
                              </div>
                            </div>
                            <div className="text-end mx-2 mb-2">
                              <button
                                className="btn btn-outline-primary me-1"
                                onClick={() => {
                                  navigate(`/shop/${product.id}`)
                                }}
                              >
                                Details
                              </button>
                              <button
                                className="btn btn-primary"
                                onClick={() => {
                                  if (product.isParent) {
                                    const toastPayload = {
                                      title: 'Select Variation',
                                      message: 'Go to Details to select a variation',
                                      isVisible: true,
                                      timestamp: dayjs().format('LT'),
                                      type: 'warning',
                                      delay: 2000
                                    }
                                    dispatch(setToast(toastPayload))
                                  } else {
                                    const availableStock = Math.max((product.stock?.stockLevel || 0) - (product.stock?.stockLevelReserved || 0), 0)
                                    if (product.isExceedStockEnabled || availableStock > 0) {
                                      const foundProductInCart = shoppingCartProductsWithQuantity.find(shoppingCartProduct => shoppingCartProduct.id === product.id)
                                      if (foundProductInCart === undefined) {
                                        const quantity = getMinimumProductOrderQuantity((product.minimumOrderQuantity ?? 1), product.graduatedPrices)
                                        const updatedProducts = [...shoppingCartProductsWithQuantity, { ...product, quantity }]
                                        const payload = {
                                          products: updatedProducts
                                        }
                                        dispatch(setShoppingCart(payload))
                                        const toastPayload = {
                                          title: 'Info',
                                          message: `${product.name} added to your cart`,
                                          isVisible: true,
                                          timestamp: dayjs().format('LT'),
                                          type: 'info',
                                          delay: 2000
                                        }
                                        dispatch(setToast(toastPayload))
                                      }
                                    } else {
                                      const toastPayload = {
                                        title: 'Out of Stock',
                                        message: `There is no available stock for ${product.name}`,
                                        isVisible: true,
                                        timestamp: dayjs().format('LT'),
                                        type: 'warning',
                                        delay: 2000
                                      }
                                      dispatch(setToast(toastPayload))
                                    }
                                  }
                                }}
                                disabled={shoppingCartProductsWithQuantity.find(cartProduct => cartProduct.id === product.id) !== undefined}
                              >
                                <i className="bi bi-cart"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      ))
                    )
                    }
                  </div>
                  : (
                    <div className="h-100 d-flex justify-content-center align-items-center">
                      {(filterCategories.length > 0 || debouncedSearchTerm) ? <p>Products with the selected filter parameters not found</p> : <p>No products available yet</p>}
                    </div>
                    )
            }
          </div>
        </div>
        <div className="row mt-4">
          <Pagination
            isLoading={false}
            metadata={{
              limit: metadata.perPage,
              total: metadata.total,
              offset: ((metadata.page - 1) * (metadata.perPage))
            }}
            page={page}
            perPage={perPage}
            handlePageChange={handlePageChange}
            handleShowEntries={handleShowEntries}
            perPageOptions={[20, 40, 60, 100]}
            module="Shop"
            isTrackingPage
          />
        </div>
      </div>
      {/* Modals */}
      <ShoppingModals
        setSelectedAddress={setSelectedAddress}
        selectedAddress={selectedAddress}
        isAllowedToWriteDeliveryAddresses={true}
        loadCostCenterOptionsDebounced={loadCostCenterOptionsDebounced}
      />
    </main>
  )
}

export default Shop
