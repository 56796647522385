import React, { useState, useEffect } from 'react'
import { object, string } from 'yup'
import { useFormik } from 'formik'
import AsyncSelect from 'react-select/async'
import dayjs from 'dayjs'
import { countries } from '../../utils/countries'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { Address, orders } from '../../types'
import { openModal } from '../../utils/openModal'
import { dismissModal } from '../../utils/dismissModal'
import { resetUserMessage, updateUserAddress } from '../../store/reducers/api/usersReducer'
import { createCatalogueOrders, resetPendingOrderMessage } from '../../store/reducers/api/pendingOrderQueueReducer'
import { setToast } from '../../store/reducers/toastReducer'
import { ORDER_CREATION_SUCCESS_MESSAGE, ADDRESS_ADDITION_SUCCESS_MESSAGE, ADDRESS_UPDATE_SUCCESS_MESSAGE } from '../../constants/messages'
import { resetShoppingAddresses, resetShoppingCart } from '../../store/reducers/shoppingCartReducer'
import { getMyProfile } from '../../store/reducers/api/profileReducer'

interface AddressEditorProps {
  initialAddress: Partial<Address> | null
  loadCostCenterOptionsDebounced: (...args: any[]) => void
  shippingMode: 'inventory' | 'delivery' | null
}

const ShoppingPayment = ({
  initialAddress,
  loadCostCenterOptionsDebounced,
  shippingMode
}: AddressEditorProps) => {
  const currentUser = useAppSelector((state) => state.apiAuth.currentUser)
  const isUpdating = useAppSelector((state) => state.apiUsers.isLoading)
  const shoppingCartAddresses = useAppSelector((state) => state.shoppingCart.addresses)
  const address = useAppSelector((state) => state.apiUsers.address)
  const message = useAppSelector((state) => state.apiPendingOrderQueue.message)
  const userMessage = useAppSelector((state) => state.apiUsers.message)

  const [paymentMode, setPaymentMode] = useState('invoice')
  const [hasAgreed, setHasAgreed] = useState<'no' | 'yes'>('no')
  const [canSaveAddress, setCanSaveAddress] = useState<'no' | 'yes'>('no')

  const token = currentUser?.token

  const dispatch = useAppDispatch()
  const addressSchema = object({
    companyName: string()
      .min(2, 'Enter a valid company name')
      .max(32, 'Company Name is too long'),
    email: string()
      .email('Enter a valid email')
      .required('Email is required'),
    vat: string()
      .nullable()
      .max(16, 'Phone number is too long'),
    country: string().required('Country is required').oneOf(countries),
    city: string()
      .required('City is required')
      .max(32, 'City name is too long'),
    street: string()
      .required('Street and House Number are required')
      .max(64, 'Street and House Number are too long'),
    zip: string()
      .label('Zip')
      .required('Zip is required')
      .max(32, 'Zip is too long'),
    addressAddition: string(),
    costCenter: string(),
    hasAgreed: string().oneOf(['yes'], 'We want to make sure you understand our policies. Please read and agree to our terms and conditions before continuing.')
  })

  const saveBillingAddress = (id: string, token: string, address: any, signal: AbortSignal) => {
    dispatch(updateUserAddress({ id, token, address, signal }))
  }

  const createOrder = (token: string, pendingOrders: any, signal: AbortSignal) => {
    dispatch(createCatalogueOrders({ token, pendingOrders, signal }))
  }

  const generatePendingOrder = () => {
    const mappedPendingOrders: Array<orders.PendingOrder> = shoppingCartAddresses.map(cartAddress => {
      const mappedOrder = {
        platform: 0,
        language: 0,
        currency: 'EUR',
        orderNo: '0',
        inetorderno: 0,
        shippingId: 7,
        shipped: dayjs(cartAddress.shippingDate).set('hour', dayjs().hour()).set('minute', dayjs().minute()).add(5, 'minutes').format(),
        deliverydate: dayjs(cartAddress.shippingDate).set('hour', dayjs().hour()).set('minute', dayjs().minute()).add(5, 'minutes').format(),
        note: cartAddress.note,
        description: '',
        costCenter: String(cartAddress.costCenter),
        paymentType: 0,
        paymentTarget: 0,
        discount: 0,
        orderStatus: 0,
        orderLineRequests: cartAddress.assignedProducts.map(assignedProduct => (
          {
            itemName: assignedProduct.name,
            articleNumber: String(assignedProduct.merchantSku),
            itemNetSale: 0,
            itemVAT: 0,
            quantity: Number(assignedProduct.quantity || 1),
            type: 0,
            discount: 0,
            netPurchasePrice: 0
          }
        )),
        shippingAddressRequests: [
          {
            salutation: cartAddress.salutation ?? '',
            firstName: cartAddress.firstName ?? '',
            lastName: cartAddress.lastName ?? '',
            title: '',
            company: cartAddress.companyName ?? '',
            companyAddition: '',
            street: cartAddress.street ?? '',
            addressAddition: cartAddress.addressAddition ?? '',
            zipCode: cartAddress.zip ?? '',
            place: cartAddress.city ?? '',
            phone: cartAddress.phone ?? '',
            state: '',
            country: cartAddress.country ?? '',
            iso: '',
            telephone: '',
            mobile: '',
            fax: '',
            email: cartAddress.email ?? ''
          }
        ]
      }
      return mappedOrder
    })
    return mappedPendingOrders
  }

  const {
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    values,
    errors,
    touched,
    isSubmitting,
    resetForm
  } = useFormik({
    initialValues: {
      ...initialAddress,
      companyName: initialAddress?.companyName ?? '',
      vat: initialAddress?.vat ?? '',
      addressAddition: initialAddress?.addressAddition ?? '',
      costCenter: initialAddress?.costCenter ?? '',
      email: initialAddress?.email ?? '',
      country: initialAddress?.country ?? '',
      city: initialAddress?.country ?? '',
      street: initialAddress?.street ?? '',
      zip: initialAddress?.zip ?? '',
      hasAgreed,
      canSaveAddress
    },
    onSubmit: (
      {
        companyName,
        email,
        vat,
        country,
        city,
        street,
        zip,
        addressAddition,
        costCenter
      },
      actions
    ) => {
      const controller = new AbortController()
      const signal = controller.signal
      const address = {
        companyName: companyName || null,
        email,
        vat: vat || null,
        country,
        city,
        street,
        zip,
        addressAddition: addressAddition || null,
        costCenter: costCenter || null,
        type: 'billing',
        affiliation: 'personal'
      }

      if (canSaveAddress === 'yes' && token && currentUser.id) {
        saveBillingAddress(currentUser.id, token, address, signal)
      }
      if (token && shippingMode === 'delivery') {
        const pendingOrders = generatePendingOrder()
        createOrder(token, pendingOrders, signal)
      }
      actions.setSubmitting(false)
    },
    validationSchema: addressSchema,
    enableReinitialize: true
  })

  useEffect(() => {
    if (message === ORDER_CREATION_SUCCESS_MESSAGE) {
      const toastPayload = {
        title: 'Success',
        message,
        isVisible: true,
        timestamp: dayjs().format('LT'),
        type: 'success'
      }
      dispatch(setToast(toastPayload))
      dispatch(resetPendingOrderMessage())
      dispatch(resetShoppingCart())
      dispatch(resetShoppingAddresses())
      dismissModal('shoppingPaymentModal')
      resetForm()
    }
  }, [message])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    const allowedMessages = [ADDRESS_ADDITION_SUCCESS_MESSAGE, ADDRESS_UPDATE_SUCCESS_MESSAGE]

    if (token && (allowedMessages.includes(userMessage) && address?.type === 'billing')) {
      dispatch(getMyProfile({ token, signal }))
      dispatch(resetUserMessage())
    }
  }, [userMessage])

  return (
    <div>
      <div className="row justify-content-center m-0">
        <div className="col-lg-7 col-md-10 col-sm-11">
          <div
            className="horizontal-steps mt-4 mb-4 pb-5"
            id="tooltip-container"
          >
            <div className="horizontal-steps-content">
              <div className="step-item">
                <span
                  data-bs-container="#tooltip-container"
                  data-bs-toggle="tooltip"
                  data-bs-placement="bottom"
                  title=""
                  data-bs-title=""
                >
                  Your Cart
                </span>
              </div>
              <div className="step-item">
                <span
                  data-bs-container="#tooltip-container"
                  data-bs-toggle="tooltip"
                  data-bs-placement="bottom"
                  title=""
                  data-bs-title=""
                >
                  Shipping Details
                </span>
              </div>
              <div className="step-item current">
                <span>Payment</span>
              </div>
            </div>
            <div className="process-line" style={{ width: '100%' }}></div>
          </div>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-12 col-lg-7">
            <h6>Billing Address</h6>
            <div className="row">
              <div className="col">
                <div className="mb-3">
                  <label htmlFor="billingCompanyName" className="form-label">
                    Company Name
                  </label>
                  <input
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.companyName}
                    type="text"
                    className={`form-control ${
                      errors.companyName &&
                      touched.companyName &&
                      errors.companyName
                        ? 'is-invalid'
                        : ''
                    }`}
                    id="billingCompanyName"
                    name="companyName"
                    placeholder=""
                  />
                  <div
                    id="validationDeliveryCompanyNameFeedback"
                    className="invalid-feedback"
                  >
                    {errors.companyName}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="billingEmail" className="form-label">
                    Email <span className="small text-primary">(for receipt of invoice)</span>
                  </label>
                  <input
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    type="email"
                    className={`form-control ${
                      errors.email && touched.email && errors.email
                        ? 'is-invalid'
                        : ''
                    }`}
                    id="billingEmail"
                    name="email"
                    placeholder=""
                    autoComplete="off"
                  />
                  <div
                    id="validationBillingEmailFeedback"
                    className="invalid-feedback"
                  >
                    {errors.email}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="billingUSTID" className="form-label">
                    USTID
                  </label>
                  <input
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.vat}
                    type="text"
                    className={`form-control ${
                      errors.vat && touched.vat && errors.vat
                        ? 'is-invalid'
                        : ''
                    }`}
                    id="billingUSTID"
                    name="vat"
                    placeholder=""
                    autoComplete="off"
                  />
                  <div
                    id="validationDeliveryVatFeedback"
                    className="invalid-feedback"
                  >
                    {errors.phone}
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="billingCountry" className="form-label">
                    Country
                  </label>
                  <select
                    aria-label="Country"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.country}
                    className={`form-select ${
                      errors.country && touched.country && errors.country
                        ? 'is-invalid'
                        : ''
                    }`}
                    id="billingCountry"
                    name="country"
                    autoComplete="off"
                  >
                    <option value="">Select Country</option>
                    {countries.map((country: string, index: number) => (
                      <option key={index}>{country}</option>
                    ))}
                  </select>
                  <div
                    id="validationDeliveryCountryFeedback"
                    className="invalid-feedback"
                  >
                    {errors.country}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="billingCity" className="form-label">
                    City
                  </label>
                  <input
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.city}
                    type="text"
                    className={`form-control ${
                      errors.city && touched.city && errors.city
                        ? 'is-invalid'
                        : ''
                    }`}
                    id="billingCity"
                    name="city"
                    placeholder=""
                  />
                  <div
                    id="validationDeliveryCityFeedback"
                    className="invalid-feedback"
                  >
                    {errors.city}
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="billingStreet" className="form-label">
                    Street and House Number
                  </label>
                  <input
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.street}
                    type="text"
                    className={`form-control ${
                      errors.street && touched.street && errors.street
                        ? 'is-invalid'
                        : ''
                    }`}
                    id="billingStreet"
                    name="street"
                    placeholder=""
                  />
                  <div
                    id="validationDeliveryStreetFeedback"
                    className="invalid-feedback"
                  >
                    {errors.street}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="billingZip" className="form-label">
                    Zip
                  </label>
                  <input
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.zip}
                    type="text"
                    className={`form-control ${
                      errors.zip && touched.zip && errors.zip ? 'is-invalid' : ''
                    }`}
                    id="billingZip"
                    name="zip"
                    placeholder=""
                  />
                  <div
                    id="validationDeliveryZipFeedback"
                    className="invalid-feedback"
                  >
                    {errors.zip}
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="mb-3">
                  <label
                    htmlFor="billingAddressAddition"
                    className="form-label"
                  >
                    Address Addition
                  </label>
                  <input
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.addressAddition}
                    type="text"
                    className={`form-control ${
                      errors.addressAddition &&
                      touched.addressAddition &&
                      errors.addressAddition
                        ? 'is-invalid'
                        : ''
                    }`}
                    id="billingAddressAddition"
                    name="addressAddition"
                    placeholder=""
                  />
                  <div
                    id="validationDeliveryAddressAdditionFeedback"
                    className="invalid-feedback"
                  >
                    {errors.addressAddition}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="billingCostCenter" className="form-label">
                    Cost Center
                  </label>
                  <AsyncSelect
                    inputId="billingCostCenter"
                    cacheOptions
                    loadOptions={loadCostCenterOptionsDebounced}
                    defaultOptions
                    onChange={(selectedOption: any) => {
                      setFieldValue('costCenter', selectedOption.value)
                    }}
                    placeholder="Select Cost Center"
                    className={`${
                      errors.costCenter && touched.costCenter ? 'is-invalid' : ''
                    }`}
                    styles={{
                      control: (provided, state) => ({
                        ...provided,
                        borderColor:
                          errors.costCenter && touched.costCenter
                            ? '#dc3545'
                            : provided.borderColor,
                        '&:hover': {
                          boxShadow:
                            errors.costCenter && touched.costCenter
                              ? '0 0 0 0.25rem rgba(220, 53, 69, 0.25)'
                              : '0 0 0 0.25rem var(--ed-primary-reduce-opacity, rgba(230, 42, 0, 0.5))',
                          borderColor:
                            errors.costCenter && touched.costCenter
                              ? '#dc3545'
                              : '#86b7fe'
                        }
                      })
                    }}
                    value={{ value: values.costCenter, label: values.costCenter }}
                  />
                  <div
                    id="validationDeliveryCostCenterFeedback"
                    className="invalid-feedback"
                  >
                    {errors.costCenter}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="mb-3">
                  <p className="mt-2 mb-1">
                    Save the billing address
                  </p>
                  <div className={`form-check form-check-inline ${errors.canSaveAddress ? 'is-invalid' : ''}`}>
                    <input
                      className={`form-check-input ${errors.canSaveAddress ? 'is-invalid' : ''}`}
                      type="radio"
                      name="canSaveAddress"
                      id="inlineRadioYes"
                      onChange={() => { setCanSaveAddress('yes') }}
                      value="yes"
                      autoComplete="off"
                      checked={values.canSaveAddress === 'yes'}
                    />
                    <label className="form-check-label" htmlFor="inlineRadioYes">Yes</label>
                  </div>
                  <div className={`form-check form-check-inline ${errors.canSaveAddress ? 'is-invalid' : ''}`}>
                    <input
                      className={`form-check-input ${errors.canSaveAddress ? 'is-invalid' : ''}`}
                      type="radio"
                      name="canSaveAddress"
                      id="inlineRadioNo"
                      onChange={() => { setCanSaveAddress('no') }}
                      value="no"
                      autoComplete="off"
                      checked={values.canSaveAddress === 'no'}
                    />
                    <label className="form-check-label" htmlFor="inlineRadioNo">No</label>
                  </div>
                  <div
                    id="validationCanSaveAddressFeedback"
                    className="invalid-feedback"
                  >
                    {errors.canSaveAddress}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className='mb-3'>
              <h6>Select Payment Method</h6>
            </div>
            <div className="row border rounded m-0">
              <div className="col">
                <div className="form-check my-3">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="inventoryRadio"
                    onChange={(event) => {
                      const value = event.target.value
                      if (value === 'on') {
                        setPaymentMode('invoice')
                      }
                    }}
                    checked={paymentMode === 'invoice'}
                  />
                  <label className="form-check-label" htmlFor="inventoryRadio">
                    Payment via Invoice
                  </label>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="mb-3">
                  <p className="mt-2">
                    By creating an order, you agree to our <button className="btn btn-link text-primary m-0 p-0" data-bs-toggle="modal" type="button" data-bs-target="#termsModal">terms and conditions</button>.
                  </p>
                  <div className={`form-check form-check-inline ${errors.hasAgreed ? 'is-invalid' : ''}`}>
                    <input
                      className={`form-check-input ${errors.hasAgreed ? 'is-invalid' : ''}`}
                      type="radio"
                      name="hasAgreed"
                      id="inlineBillingAddressRadioYes"
                      onChange={() => setHasAgreed('yes')}
                      value="yes"
                      autoComplete="off"
                      checked={values.hasAgreed === 'yes'}
                    />
                    <label className="form-check-label" htmlFor="inlineBillingAddressRadioYes">Yes</label>
                  </div>
                  <div className={`form-check form-check-inline ${errors.hasAgreed ? 'is-invalid' : ''}`}>
                    <input
                      className={`form-check-input ${errors.hasAgreed ? 'is-invalid' : ''}`}
                      type="radio"
                      name="hasAgreed"
                      id="inlineBillingAddressRadioNo"
                      onChange={() => setHasAgreed('no')}
                      value="no"
                      autoComplete="off"
                      checked={values.hasAgreed === 'no'}
                    />
                    <label className="form-check-label" htmlFor="inlineBillingAddressRadioNo">No</label>
                  </div>
                  <div
                    id="validationHasAgreedFeedback"
                    className="invalid-feedback"
                  >
                    {errors.hasAgreed}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="border-top">
          <div className="text-end mt-3">
            <button
              className="btn btn-secondary me-2"
              type="button"
              onClick={() => {
                openModal('shoppingShippingDetailsModal')
                dismissModal('shoppingPaymentModal')
              }}
            >
              Back
            </button>
            <button
              type="submit"
              className="btn btn-primary"
              disabled={isSubmitting || isUpdating}
            >
              Confirm Payment
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default ShoppingPayment
