import { createSlice } from '@reduxjs/toolkit'
import { ShoppingCartState } from '../../types'

const initialState: ShoppingCartState = {
  products: [],
  addresses: []
}

const shoppingCartSlice = createSlice({
  name: 'shopping-cart',
  initialState,
  reducers: {
    setShoppingCart: (state, action) => {
      const { products } = action.payload
      state.products = products
    },
    resetShoppingCart: (state) => {
      state.products = []
    },
    setShoppingAddresses: (state, action) => {
      const { addresses } = action.payload
      state.addresses = addresses
    },
    resetShoppingAddresses: (state) => {
      state.addresses = []
    }
  }
})

export const { setShoppingCart, resetShoppingCart, setShoppingAddresses, resetShoppingAddresses } = shoppingCartSlice.actions

const { reducer } = shoppingCartSlice
export default reducer
