import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import ProductService from '../../../services/api/ProductService'
import { ApiProductState, Product, ProductGraduatedPrice, ProductUpdate } from '../../../types'
import {
  PRODUCT_DELETION_MESSAGE,
  PRODUCT_UPDATE_MESSAGE,
  PRODUCT_TAGS_UPDATE_MESSAGE,
  PRODUCT_GRADUATED_PRICE_CREATION_MESSAGE,
  PRODUCT_COMPANY_UPDATE_MESSAGE
} from '../../../constants/messages'

const initialState: ApiProductState = {
  isLoading: false,
  error: null,
  product: null,
  products: [],
  catalogueProduct: null,
  catalogueProducts: [],
  stock: null,
  message: null,
  metadata: {
    page: 1,
    pageCount: 1,
    perPage: 20,
    total: 0
  },
  isLoadingOutbounds: false,
  outbounds: [],
  outboundsMetadata: {
    page: 1,
    pageCount: 1,
    perPage: 20,
    total: 0
  },
  isLoadingInbounds: false,
  inbounds: [],
  inboundsMetadata: {
    page: 1,
    pageCount: 1,
    perPage: 20,
    total: 0
  },
  productTags: [],
  productTagsMetadata: {
    page: 1,
    pageCount: 1,
    perPage: 20,
    total: 0
  },
  isLoadingProductTags: false,
  productVariations: [],
  productVariationsMetadata: {
    page: 1,
    pageCount: 1,
    perPage: 20,
    total: 0
  },
  isLoadingProductVariations: false
}

export const getAllProducts = createAsyncThunk('api/products/get', async ({ token, perPage, page, search, signal, orderBy, filter }: { token: string, perPage: number, page: number, search: string, signal: AbortSignal, orderBy?: string, filter?: string }, { rejectWithValue }) => {
  try {
    const res = await ProductService.getAllProducts(token, perPage, page, search, signal, orderBy, filter)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const getAllCatalogueProducts = createAsyncThunk('api/products/catalogue/get', async ({ token, perPage, page, search, signal, orderBy, filter }: { token: string, perPage: number, page: number, search: string, signal: AbortSignal, orderBy?: string, filter?: string }, { rejectWithValue }) => {
  try {
    const res = await ProductService.getAllCatalogueProducts(token, perPage, page, search, signal, orderBy, filter)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const getSimilarCatalogueProducts = createAsyncThunk('api/products/catalogue/similar/get', async ({ token, productId, perPage, page, signal }: { token: string, productId: string, perPage: number, page: number, signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await ProductService.getSimilarCatalogueProducts(token, productId, perPage, page, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const addProduct = createAsyncThunk('api/products/add', async ({ token, product, signal }: { token: string, product: Partial<Product>, signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await ProductService.addProduct(token, product, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const getAProductById = createAsyncThunk('api/product/get', async ({ token, productId, signal }: { token: string, productId: string, signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await ProductService.getProductById(token, productId, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const getACatalogueProductById = createAsyncThunk('api/product/get/catalogue', async ({ token, productId, signal }: { token: string, productId: string, signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await ProductService.getCatalogueProductById(token, productId, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const editAProductById = createAsyncThunk('api/products/edit', async ({ token, productId, product, signal }: { token: string, productId: string, product: Partial<ProductUpdate>, signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await ProductService.editProductById(token, productId, product, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const updateAProductCompanyById = createAsyncThunk('api/products/edit/company', async ({ token, productId, product, signal }: { token: string, productId: string, product: { companyId: string | null }, signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await ProductService.updateProductCompanyById(token, productId, product, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const deleteAProductById = createAsyncThunk('api/products/delete', async ({ token, productId, signal }: { token: string, productId: string, signal: AbortSignal}, { rejectWithValue }) => {
  try {
    const res = await ProductService.deleteProductById(token, productId, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const getAProductStock = createAsyncThunk('api/products/stock', async ({ token, productId, signal }: { token: string, productId: string, signal: AbortSignal}, { rejectWithValue }) => {
  try {
    const res = await ProductService.getProductStock(token, productId, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error?.response.data)
  }
})

export const getAProductOutbounds = createAsyncThunk('api/products/outbounds', async ({ token, perPage, page, productId, signal }: { token: string, perPage: number, page: number, productId: string, signal: AbortSignal}, { rejectWithValue }) => {
  try {
    const res = await ProductService.getProductOutbounds(token, perPage, page, productId, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error?.response.data)
  }
})

export const getAProductInbounds = createAsyncThunk('api/products/inbounds', async ({ token, perPage, page, productId, signal }: { token: string, perPage: number, page: number, productId: string, signal: AbortSignal}, { rejectWithValue }) => {
  try {
    const res = await ProductService.getProductInbounds(token, perPage, page, productId, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error?.response.data)
  }
})

export const addCategoryTagToProduct = createAsyncThunk('api/products/productCategoryTags/post', async ({ token, productId, productTag, signal }: { token: string, productId: string, productTag: any, signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await ProductService.addCategoryTagToProduct(token, productId, productTag, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error?.response.data)
  }
})

export const addGraduatedPriceToProduct = createAsyncThunk('api/products/productCategoryGraduatedPrices/post', async ({ token, productId, productGraduatedPrice, signal }: { token: string, productId: string, productGraduatedPrice: Pick<ProductGraduatedPrice, 'price' | 'firstUnit' | 'lastUnit'>, signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await ProductService.addGraduatedPriceToProduct(token, productId, productGraduatedPrice, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error?.response.data)
  }
})

export const getAProductVariations = createAsyncThunk('api/products/catalogue/variations', async ({ token, perPage, page, productId, signal, filter }: { token: string, perPage: number, page: number, productId: string, signal: AbortSignal, filter?: string}, { rejectWithValue }) => {
  try {
    const res = await ProductService.getProductVariations(token, perPage, page, productId, signal, filter)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error?.response.data)
  }
})

const productSlice = createSlice({
  name: 'api/product',
  initialState,
  reducers: {
    resetProductError: (state) => {
      state.error = null
    },
    resetProductMessage: (state) => {
      state.message = null
    },
    resetProductData: (state) => {
      state.error = null
      state.product = null
      state.products = []
      state.message = null
      state.outbounds = []
      state.inbounds = []
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllProducts.pending, (state) => {
        state.products = []
        state.isLoading = true
      })
      .addCase(getAllProducts.fulfilled, (state, action) => {
        state.isLoading = false
        state.products = action.payload.products
        state.metadata = action.payload.meta
        state.error = null
        state.message = null
      })
      .addCase(getAllProducts.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
    builder
      .addCase(getAProductById.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getAProductById.fulfilled, (state, action) => {
        state.isLoading = false
        state.product = action.payload.product
        state.error = null
        state.message = null
      })
      .addCase(getAProductById.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
    builder
      .addCase(getAllCatalogueProducts.pending, (state) => {
        state.catalogueProducts = []
        state.isLoading = true
      })
      .addCase(getAllCatalogueProducts.fulfilled, (state, action) => {
        state.isLoading = false
        state.catalogueProducts = action.payload.products
        state.metadata = action.payload.meta
        state.error = null
        state.message = null
      })
      .addCase(getAllCatalogueProducts.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
    builder
      .addCase(getACatalogueProductById.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getACatalogueProductById.fulfilled, (state, action) => {
        state.isLoading = false
        state.catalogueProduct = action.payload.product
        state.error = null
        state.message = null
      })
      .addCase(getACatalogueProductById.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
    builder
      .addCase(getSimilarCatalogueProducts.pending, (state) => {
        state.productTags = []
        state.isLoadingProductTags = true
      })
      .addCase(getSimilarCatalogueProducts.fulfilled, (state, action) => {
        state.isLoadingProductTags = false
        state.productTags = action.payload.productTags
        state.productTagsMetadata = action.payload.meta
        state.error = null
        state.message = null
      })
      .addCase(getSimilarCatalogueProducts.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoadingProductTags = false
        }
        state.error = action.payload
      })
    builder
      .addCase(addProduct.pending, (state) => {
        state.isLoading = true
      })
      .addCase(addProduct.fulfilled, (state, action) => {
        state.isLoading = false
        state.product = action.payload.product
        state.error = null
        state.message = null
      })
      .addCase(addProduct.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
    builder
      .addCase(editAProductById.pending, (state) => {
        state.isLoading = true
      })
      .addCase(editAProductById.fulfilled, (state, action) => {
        state.isLoading = false
        state.product = action.payload.product
        state.error = null
        state.message = PRODUCT_UPDATE_MESSAGE
      })
      .addCase(editAProductById.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
    builder
      .addCase(updateAProductCompanyById.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updateAProductCompanyById.fulfilled, (state, action) => {
        state.isLoading = false
        state.product = action.payload.product
        state.error = null
        state.message = PRODUCT_COMPANY_UPDATE_MESSAGE
      })
      .addCase(updateAProductCompanyById.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
    builder
      .addCase(deleteAProductById.pending, (state) => {
        state.isLoading = true
      })
      .addCase(deleteAProductById.fulfilled, (state, action) => {
        state.isLoading = false
        state.product = null
        state.error = null
        state.message = PRODUCT_DELETION_MESSAGE
      })
      .addCase(deleteAProductById.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
    builder
      .addCase(getAProductStock.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getAProductStock.fulfilled, (state, action) => {
        state.isLoading = false
        state.stock = action.payload.stock
        state.error = null
      })
      .addCase(getAProductStock.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
    builder
      .addCase(getAProductOutbounds.pending, (state) => {
        state.isLoadingOutbounds = true
      })
      .addCase(getAProductOutbounds.fulfilled, (state, action) => {
        state.isLoadingOutbounds = false
        state.outbounds = action.payload.outbounds
        state.outboundsMetadata = action.payload.meta
        state.error = null
      })
      .addCase(getAProductOutbounds.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoadingOutbounds = false
        }
        state.error = action.payload
      })
    builder
      .addCase(getAProductInbounds.pending, (state) => {
        state.isLoadingInbounds = true
      })
      .addCase(getAProductInbounds.fulfilled, (state, action) => {
        state.isLoadingInbounds = false
        state.inbounds = action.payload.inbounds
        state.inboundsMetadata = action.payload.meta
        state.error = null
      })
      .addCase(getAProductInbounds.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoadingInbounds = false
        }
        state.error = action.payload
      })
    builder
      .addCase(addCategoryTagToProduct.pending, (state) => {
        state.isLoading = true
      })
      .addCase(addCategoryTagToProduct.fulfilled, (state, action) => {
        state.isLoading = false
        state.message = PRODUCT_TAGS_UPDATE_MESSAGE
        state.error = null
      })
      .addCase(addCategoryTagToProduct.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
    builder
      .addCase(addGraduatedPriceToProduct.pending, (state) => {
        state.isLoading = true
      })
      .addCase(addGraduatedPriceToProduct.fulfilled, (state, action) => {
        state.isLoading = false
        state.message = PRODUCT_GRADUATED_PRICE_CREATION_MESSAGE
        state.error = null
      })
      .addCase(addGraduatedPriceToProduct.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
    builder
      .addCase(getAProductVariations.pending, (state) => {
        state.productVariations = []
        state.isLoadingProductVariations = true
      })
      .addCase(getAProductVariations.fulfilled, (state, action) => {
        state.isLoadingProductVariations = false
        state.productVariations = action.payload.products
        state.productVariationsMetadata = action.payload.meta
      })
      .addCase(getAProductVariations.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoadingProductVariations = false
        }
        state.error = action.payload
      })
  }
})

export const { resetProductError, resetProductMessage, resetProductData } = productSlice.actions

const { reducer } = productSlice
export default reducer
