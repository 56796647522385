import React, { useState } from 'react'
import dayjs from 'dayjs'
import ShoppingCart from './ShoppingCart'
import ShoppingShippingDetails from './ShoppingShippingDetails'
import { dismissModal } from '../../utils/dismissModal'
import { openModal } from '../../utils/openModal'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import ShoppingDeliveryAddressPicker from './ShoppingDeliveryAddressPicker'
import { Address, ShoppingCartAddress } from '../../types'
import { setShoppingAddresses } from '../../store/reducers/shoppingCartReducer'
import ShoppingDeliveryAddressEditor from './ShoppingDeliveryAddressEditor'
import ShoppingDetailsAssignArticles from './ShoppingDetailsAssignArticles'
import AddressEditor from './AddressEditor'
import { updateUserAddress } from '../../store/reducers/api/usersReducer'
import Progress from '../loaders/Progress'
import { setToast } from '../../store/reducers/toastReducer'
import ShoppingPayment from './ShoppingPayment'
import Terms from '../Terms'

interface ShoppingModalsProps {
  setSelectedAddress: React.Dispatch<React.SetStateAction<Partial<Address> | null>>
  selectedAddress: Partial<Address> | null
  isAllowedToWriteDeliveryAddresses: boolean
  loadCostCenterOptionsDebounced: any
}
const ShoppingModals = ({
  setSelectedAddress,
  selectedAddress,
  isAllowedToWriteDeliveryAddresses,
  loadCostCenterOptionsDebounced
}: ShoppingModalsProps) => {
  const profile = useAppSelector((state) => state.profile.profile)
  const shoppingCartProducts = useAppSelector((state) => state.shoppingCart.products)
  const shoppingCartAddresses = useAppSelector((state) => state.shoppingCart.addresses)
  const isLoadingUserAddress = useAppSelector((state) => state.apiUsers.isLoading)
  const legalTexts = useAppSelector((state) => state.apiCompany.legalTexts)

  const [selectedAssignmentAddress, setSelectedAssignmentAddress] = useState<ShoppingCartAddress | null>(null)
  const [shippingMode, setShippingMode] = useState<'inventory' | 'delivery' | null>('delivery')

  const billingOrbillingAndDeliveryAddress = profile?.addresses?.find(address => address.type === 'billing' || address.type === 'billingAndDelivery')

  const dispatch = useAppDispatch()

  const totalAssignedArticles = () => {
    return shoppingCartAddresses
      .flatMap(cartAddress => cartAddress.assignedProducts.length)
      .reduce((acc, curr) => acc + curr, 0)
  }

  return (
    <>
      <div className="modal fade" id="shoppingCartModal" data-bs-backdrop="static" tabIndex={-1} aria-labelledby="shoppingCartModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="shoppingCartModalLabel"><i className="bi bi-cart me-1"></i>Shopping Cart</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <ShoppingCart />
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  dismissModal('shoppingCartModal')
                  openModal('shoppingShippingDetailsModal')
                }}
                disabled={shoppingCartProducts.length === 0}
              >
                Checkout <i className="bi bi-arrow-right ms-1"></i>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="shoppingShippingDetailsModal" data-bs-backdrop="static" tabIndex={-1} aria-labelledby="shoppingShippingDetailsModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="shoppingShippingDetailsModalLabel"><i className="bi bi-cart me-1"></i>Shopping Cart</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <ShoppingShippingDetails
                setSelectedAssignmentAddress={setSelectedAssignmentAddress}
                shippingMode={shippingMode}
                setShippingMode={setShippingMode}
              />
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => {
                  dismissModal('shoppingShippingDetailsModal')
                  openModal('shoppingCartModal')
                }}
              >
                Back
              </button>
              <button
                type="button"
                className="btn btn-primary"
                disabled={shoppingCartAddresses.length === 0}
                onClick={() => {
                  if (totalAssignedArticles() === 0) {
                    const toastPayload = {
                      title: 'Warning',
                      message: 'Assign the articles to a delivery address',
                      isVisible: true,
                      timestamp: dayjs().format('LT'),
                      type: 'warning',
                      delay: 2000
                    }
                    dispatch(setToast(toastPayload))
                  } else if (totalAssignedArticles() !== shoppingCartProducts.length) {
                    const toastPayload = {
                      title: 'Warning',
                      message: 'Assign the remaining articles to a delivery address',
                      isVisible: true,
                      timestamp: dayjs().format('LT'),
                      type: 'warning',
                      delay: 2000
                    }
                    dispatch(setToast(toastPayload))
                  } else {
                    openModal('shoppingPaymentModal')
                    dismissModal('shoppingShippingDetailsModal')
                  }
                }}
              >
                Confirm and Pay <i className="bi bi-arrow-right ms-1"></i>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="shoppingDeliveryAddressPickerModal" data-bs-backdrop="static" tabIndex={-1} aria-labelledby="shoppingDeliveryAddressPickerModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="shoppingDeliveryAddressPickerModalLabel"><i className="bi bi-truck me-1"></i>Shipping Details - Select delivery address</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            {isLoadingUserAddress ? <Progress marginBottom={false} /> : <hr className="border border-1 opacity-50 m-0"></hr>}
            <div className="modal-body">
              <ShoppingDeliveryAddressPicker
                isLoading={isLoadingUserAddress}
                setSelectedAddress={setSelectedAddress}
                selectedAddress={selectedAddress}
                isAllowedToWriteDeliveryAddresses={isAllowedToWriteDeliveryAddresses}
              />
            </div>
            <div className="modal-footer justify-content-between">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => {
                  setSelectedAddress(null)
                }}
                disabled={!selectedAddress}
              >
                Reset Selection
              </button>
              <div>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => {
                    dismissModal('shoppingDeliveryAddressPickerModal')
                    openModal('shoppingShippingDetailsModal')
                    if (selectedAddress) {
                      const updatedAddresses = shoppingCartAddresses.filter(address => address.id !== selectedAddress.id)
                      const payload = {
                        addresses: updatedAddresses
                      }
                      dispatch(setShoppingAddresses(payload))
                      setSelectedAddress(null)
                    }
                  }}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-primary ms-2"
                  onClick={() => {
                    dismissModal('shoppingDeliveryAddressPickerModal')
                    openModal('shoppingDeliveryAddressEditorModal')
                  }}
                  disabled={!selectedAddress}
                >
                  Select
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="shoppingDeliveryAddressEditorModal" data-bs-backdrop="static" tabIndex={-1} aria-labelledby="shoppingDeliveryAddressEditorModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="shoppingDeliveryAddressEditorModalLabel"><i className="bi bi-cart me-1"></i>Shipping Details - Add delivery address</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <ShoppingDeliveryAddressEditor
                shippingAddress={selectedAddress}
                loadCostCenterOptionsDebounced={loadCostCenterOptionsDebounced}
                setSelectedDeliveryAddress={setSelectedAddress}
                setSelectedAddress={setSelectedAddress}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="shoppingDetailsAssignArticlesModal" data-bs-backdrop="static" tabIndex={-1} aria-labelledby="shoppingDetailsAssignArticlesModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="shoppingDetailsAssignArticlesModalLabel"><i className="bi bi-cart me-1"></i>Shopping Details - Assign Articles</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              {
                selectedAssignmentAddress
                  ? (<ShoppingDetailsAssignArticles selectedAssignmentAddress={selectedAssignmentAddress} setSelectedAssignmentAddress={setSelectedAssignmentAddress} />)
                  : (<p>No assignment address selected</p>)
              }
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => {
                  dismissModal('shoppingDetailsAssignArticlesModal')
                  openModal('shoppingShippingDetailsModal')
                }}
              >
                Back
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  dismissModal('shoppingDetailsAssignArticlesModal')
                  openModal('shoppingShippingDetailsModal')
                }}
              >
                Assign Articles
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="addressEditorModal" data-bs-backdrop="static" tabIndex={-1} aria-labelledby="addressEditorModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="addressEditorModalLabel"><i className="bi bi-cart me-1"></i>Shopping Details - Delivery Address Editor</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            {isLoadingUserAddress ? <Progress marginBottom={false} /> : <hr className="border border-1 opacity-50 m-0"></hr>}
            <div className="modal-body">
              <AddressEditor
                initialAddress={selectedAddress}
                id={profile?.id || ''}
                loadCostCenterOptionsDebounced={loadCostCenterOptionsDebounced}
                save={updateUserAddress}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="shoppingPaymentModal" data-bs-backdrop="static" tabIndex={-1} aria-labelledby="shoppingPaymentModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="shoppingPaymentModalLabel"><i className="bi bi-cart me-1"></i>Shopping Cart</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <hr className="border border-1 opacity-50 m-0"></hr>
            <div className="modal-body">
              <ShoppingPayment
                initialAddress={billingOrbillingAndDeliveryAddress ?? null}
                loadCostCenterOptionsDebounced={loadCostCenterOptionsDebounced}
                shippingMode={shippingMode}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="termsModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="termsModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-3" id="termsModalLabel">Terms and Conditions</h1>
              <button type="button" className="btn-close" data-bs-toggle="modal" data-bs-target="#shoppingPaymentModal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <Terms terms={legalTexts.find(legalText => legalText.type === 'terms')?.template} />
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#shoppingPaymentModal">Understood</button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ShoppingModals
